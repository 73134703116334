import Hero from '../components/Hero';
import heroImage from '../img/heros/4042.jpg';

const NoPage = () => {
  return <>
    <Hero image imageSrc={heroImage} />
    <div className="app__background app__background--short">
      <main className="app__layout">
        <div className={`card`}>
          <div className='col card__col center'>
            <h1 className="error">404</h1>
            <h1 className="card__title">It seems you got kicked out!<br></br><br></br>There's nothing here...</h1>
          </div>
        </div>
      </main>
    </div>
  </>;
};

export default NoPage;

import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';

const GalleryModal = ({ closeModal, hasNext, hasPrev, findNext, findPrev, image }) => {
  const handleKeyDown = (e) => {
    if (e.keyCode === 27) closeModal();
    if (e.keyCode === 37 && hasPrev) findPrev();
    if (e.keyCode === 39 && hasNext) findNext();
  };

  useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);
    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  if (!image) {
    return null;
  }

  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://skunksters.com/Gallery" />
      </Helmet>
      <div className="modal-overlay" onClick={closeModal}></div>
      <div className="modal">
        <div className='modal-body'>
          <a href="#" className='modal__button modal-close' onClick={closeModal}>&times;</a>
          {hasPrev && <a href="#" className='modal__button modal-prev' onClick={findPrev}>&lsaquo;</a>}
          {hasNext && <a href="#" className='modal__button modal-next' onClick={findNext}>&rsaquo;</a>}
          <div className="modal__img-container">
            <img src={image.src} alt={image.alt} />
            {image.location &&
              <a className="gallery__location gallery__location--modal" target="_blank" href={image.locationLink} rel="noreferrer">{image.location} {image.date}</a>
            }
            {image.photographer &&
              <a className="gallery__photographer gallery__photographer--modal" target="_blank" href={image.photographerLink} rel="noreferrer">{image.photographer}</a>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryModal;

import React from "react";

const Cardhalf = (props) => {
  return (
    <div className={`card card--half card--${props.type}`}>
      <div className='col card__col'>
        <h1 className="card__title">{props.title}</h1>
        <p className="card__text">{props.text}</p>
      </div>
      <div className={`col card__col card__col--${props.coltype}`}>
        {props.image &&
          <img className={`card__image card__image--${props.imageType}`} src={props.image} alt={props.title} />
        }
      </div>
    </div>
  );
};

export default Cardhalf;

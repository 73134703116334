import React, { useLayoutEffect, useRef } from "react";
import { Helmet } from 'react-helmet';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Cardhalf from "../components/Cardhalf";
import MainVideo from "../components/MainVideo";
import Carousel from "../components/Carousel";
import Hero from '../components/Hero';
import skunkImage from '../img/logo/transparent.png';
import bandImage from '../img/backgrounds/bandgroup.jpeg';
import tshirt1 from '../img/backgrounds/tshirt1.jpeg';
import tshirt2 from '../img/backgrounds/tshirt2.jpeg';

gsap.registerPlugin(ScrollTrigger);

const HomePage = () => {
  const cards = useRef([]);
  cards.current = [];

  useLayoutEffect(() => {
    cards.current.map((item, index) => {
      gsap.fromTo(item,
        {
          opacity: 0
        },
        {
          scrollTrigger: {
            trigger: item,
            start: "-100 center",
            end: "bottom top",
          },
          duration: 2,
          opacity: 1,
          ease: "power2",
        }
      );
    });
  });

  const card = (item) => {
    if (item) {
      cards.current.push(item);
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://skunksters.com/" />
      </Helmet>
      <Hero video />
      <div className="app__background">
        <main className="app__layout">
          <div className="fade-in">
            <Cardhalf
              title={""}
              text={
                "We are a West Midlands based covers band delivering heavy rock songs and \"Skunked up\" pop songs with a heavier twist! \n\nIf you would like to book us for an event you can drop us an email or follow one of our social links."
              }
              image={bandImage}
              imageType="clipped"
              type="reverse"
            ></Cardhalf>
          </div>
          <div ref={card}>
            <Carousel />
          </div>
          <div ref={card}>
            <div className={`card card--half card--`}>
              <div className={`col card__col card__col--padtop`}>
                <img className={`card__image card__image--clipped`} src={tshirt2} alt="tshirt example 1" />
              </div>
              <div className='col card__col'>
                <h1 className="card__title">Already a fan? Fancy a Tshirt?</h1>
                <p className="card__text">Drop us a message via <a className="footer__link" rel="noreferrer" href="mailto:kalashnikovskunk@yahoo.com">email</a> or <a className="footer__link" rel="noreferrer" target="_blank" href="https://www.facebook.com/kalashnikov.skunk/">facebook</a>  to order your tshirt. Alternatively these are available at our gigs. <br></br><br></br>T-shirts are £20 at our gigs, or £20 plus postage and packaging if you want one delivered.</p>
              </div>
              <div className={`col card__col card__col--padbottom`}>
                <img className={`card__image card__image--clippedAlt`} src={tshirt1} alt="tshirt example 2" />
              </div>
            </div>
          </div>
          <div ref={card}>
            <Cardhalf
              title={"The mascot known as Fusty"}
              text={
                "\"We wanted something unique, like Iron Maiden have with \"Eddie\", with a connection to our name.\" - Andy \n\nWe then needed someone to bring our concept to life, so Andy approached an artist friend.\n\n\"In very quick time, \"Fusty\" was born, thanks to the amazing talent of Rose Bastin.\" - Andy"
              }
              image={skunkImage}
            ></Cardhalf>
          </div>
        </main >
      </div >
    </>
  );
};


export default HomePage;
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./scss/App.scss"
import ScrollToTop from "./util/ScrollToTop";
import Layout from "./pages/Layout";
import Home from "./pages/HomePage";
import Gallery from "./pages/Gallery.js";
import About from "./pages/About";
import Gigs from "./pages/Gigs";
import NoPage from "./pages/NoPage";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/gigs" element={<Gigs />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

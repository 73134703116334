const link45Live = 'https://www.45live.co.uk/'
const linkLoadStreet = 'https://www.facebook.com/loadstreetstudios'
const linkACarrigan = 'https://www.loadstreetstudios.com/acphotography'
const linkCJohn = 'https://www.facebook.com/CJohnPhotoVideo'

const aCarrigan = 'A Carrigan'
const cJohn = 'C John'

export const imgUrls = [
  {
    src: require('../gallery/2024/6.jpg'),
    alt: 'Upton blues festival',
    date: '',
    location: 'The blues festival - Plough upton',
    position: 'top',
  },
  {
    src: require('../gallery/2024/1.jpeg'),
    alt: '7 stars kidderminster',
    date: '',
    location: 'Seven stars kidderminster',
    position: 'top',
  },
  {
    src: require('../gallery/2024/2.jpeg'),
    alt: '7 stars kidderminster',
    date: '',
    location: 'Seven stars kidderminster',
    position: 'top',
  },
  {
    src: require('../gallery/2024/3.jpeg'),
    alt: '7 stars kidderminster',
    date: '',
    location: 'Seven stars kidderminster',
    position: 'top',
  },
  {
    src: require('../gallery/2024/4.jpeg'),
    alt: '7 stars kidderminster',
    date: '',
    location: 'Seven stars kidderminster',
    position: 'top',
  },
  {
    src: require('../gallery/2024/5.jpeg'),
    alt: '7 stars kidderminster',
    date: '',
    location: 'Seven stars kidderminster',
    position: 'top',
  },
  {
    src: require('../gallery/2023//ADaltbw.jpg'),
    alt: 'studio',
    date: '',
    position: 'top',
  },
  {
    src: require('../gallery/2023//IMG_4728.jpg'),
    alt: 'studio',
    date: '',
    position: 'top',
  },
  {
    src: require('../gallery/2023//IMG_4689.jpg'),
    alt: 'studio',
    date: '',
    position: 'top',
  },
  {
    src: require('../gallery/2023//WMF_ANDY.jpg'),
    alt: 'worcester music festival 1',
    date: '16.09.2023',
    location: 'Main stage worcester music festival',
    locationLink: 'https://www.worcestermusicfestival.co.uk/',
    photographer: cJohn,
    photographerLink: linkCJohn,
    position: 'top',
  },
  {
    src: require('../gallery/2023//WMF_EMMA.jpg'),
    alt: 'worcester music festival 1',
    date: '16.09.2023',
    location: 'Main stage worcester music festival',
    locationLink: 'https://www.worcestermusicfestival.co.uk/',
    photographer: cJohn,
    photographerLink: linkCJohn,
    position: 'top',
  },
  {
    src: require('../gallery/2023//IMG_12.jpeg'),
    alt: 'worcester music festival 1',
    date: '16.09.2023',
    location: 'Main stage worcester music festival',
    locationLink: 'https://www.worcestermusicfestival.co.uk/',
    position: 'top',
  },
  {
    src: require('../gallery/2023//IMG_11.jpeg'),
    alt: 'worcester music festival 2',
    date: '16.09.2023',
    location: 'Main stage worcester music festival',
    locationLink: 'https://www.worcestermusicfestival.co.uk/',
    position: 'top',
  },
  {
    src: require('../gallery/2023//IMG_13.jpg'),
    alt: 'Upton blues festival',
    date: '21.07.2023',
    location: 'Upton blues festival, the plough',
    locationLink: 'https://www.facebook.com/theploughinnatupton/?locale=en_GB',
    position: 'top',
  },
  {
    src: require('../gallery/2023//IMG_5.jpg'),
    alt: '45 Live 26.5.2023',
    date: '26.5.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//IMG_1.jpg'),
    alt: '45 Live 26.5.2023',
    date: '26.5.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//IMG_2.jpg'),
    alt: '45 Live 26.5.2023',
    date: '26.5.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//IMG_10.jpg'),
    alt: '45 Live 26.5.2023',
    date: '26.5.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//IMG_4.jpg'),
    alt: '45 Live 26.5.2023',
    date: '26.5.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//IMG_6.jpg'),
    alt: '45 Live 26.5.2023',
    date: '26.5.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//45Live1.jpg'),
    alt: '45 Live 5.2.2023',
    date: '5.2.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//45Live4.jpg'),
    alt: '45 Live 5.2.2023',
    date: '5.2.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//45Live5.jpg'),
    alt: '45 Live 5.2.2023',
    date: '5.2.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//45Live6.jpg'),
    alt: '45 Live 5.2.2023',
    date: '5.2.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//45Live8.jpg'),
    alt: '45 Live 5.2.2023',
    date: '5.2.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//45Live9.jpg'),
    alt: '45 Live 5.2.2023',
    date: '5.2.2023',
    location: '45 Live, Kidderminster',
    locationLink: link45Live,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//KS_MGP-3.jpg'),
    alt: 'Andy singing friends and family gig',
    position: 'top',
  },
  {
    src: require('../gallery/2023//LoadSt1.jpg'),
    alt: 'Load Street Studios',
    location: 'Load Street Studios',
    locationLink: linkLoadStreet,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//LoadSt2.jpeg'),
    alt: 'Load Street Studios',
    location: 'Load Street Studios',
    locationLink: linkLoadStreet,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//LoadSt6.jpg'),
    alt: 'Load Street Studios',
    location: 'Load Street Studios',
    locationLink: linkLoadStreet,
    photographer: aCarrigan,
    photographerLink: linkACarrigan,
    position: 'top',
  },
  {
    src: require('../gallery/2023//LoadSt9.jpeg'),
    alt: 'Adam at Load Street Studios',
    location: 'Load Street Studios',
    locationLink: linkLoadStreet,
    position: 'top',
  },
];

export default imgUrls
import facebookIcon from "../img/icons/facebook.svg";
import instagramIcon from "../img/icons/instagram.svg";
import emailIcon from "../img/icons/email.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__wrapper">
        <div className="col">
          <p className="footer__text">Contact us</p>
          <p className="footer__text"><a target="_blank" href="mailto:kalashnikovskunk@yahoo.com" className="footer__link" rel="noreferrer">kalashnikovskunk@yahoo.com</a></p>
          <div className="footer__social">
            <a className="footer__icon" href="https://www.facebook.com/kalashnikov.skunk/" target="_blank" rel="noreferrer">
              <img src={facebookIcon} alt="facebook" width="30" height="30" />
            </a>
            <a className="footer__icon" href="https://www.instagram.com/kalashnikov_skunk/" target="_blank" rel="noreferrer">
              <img src={instagramIcon} alt="instagram" width="30" height="30" />
            </a>
            <a className="footer__icon" href="mailto:kalashnikovskunk@yahoo.com" target="_blank" rel="noreferrer">
              <img src={emailIcon} alt="email" width="30" height="30" />
            </a>
          </div>
        </div>
        <div className="col footer__col">
          <p className="footer__text">© Copyright Kalashnikov Skunk</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useLayoutEffect, useRef } from "react";
import { Helmet } from 'react-helmet';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CardAbout from "../components/CardAbout";
import Hero from "../components/Hero";
import heroImage from "../img/heros/newsshot.jpg";
import emma from "../img/about/Em24.jpg";
import andy from "../img/about/Andy24.jpg";
import adam from "../img/about/Ad24.jpg";
import nige from "../img/about/Nige24.jpg";

gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const cards = useRef([]);
  cards.current = [];

  useLayoutEffect(() => {
    cards.current.map((item, index) => {
      gsap.fromTo(item,
        {
          opacity: 0
        },
        {
          scrollTrigger: {
            trigger: item,
            start: "-100 center",
            end: "bottom top",
          },
          duration: 2,
          opacity: 1,
          ease: "power2",
        }
      );
    });
  });

  const card = (item) => {
    if (item) {
      cards.current.push(item);
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://skunksters.com/About" />
      </Helmet>
      <Hero image imageSrc={heroImage} />
      <div className="app__background">
        <div className="app__layout">
          <h1 className="card__title--large">Meet the skunks</h1>
          <div ref={card}>
            <CardAbout
              title={"Andy"}
              q1={"Vocals"}
              q2={"Mainly Classic Metal/Rock including Black Sabbath, Judas Priest, Free, Led Zeppelin, Rush, Whitesnake, Dio, Queensryche, Tyketto, Mr Big, etc."}
              q3={"Music, History, Reading, Gaming, American Football, Puns (especially ones that make you groan)."}
              q4={"It varies. Currently it's  \"Fear Of The Dark\"."}
              q5={"\"Under the table, Greebo sat and washed himself. Occasionally he burped. Vampires have risen from the dead, the grave and the crypt, but never managed it from the cat\”. - Witches Abroad by Terry Pratchett"}
              q6={"Can't choose between Ozzy Osbourne (with Randy Rhoads), Rush and Rammstein."}
              image={andy}
              type="half"
              imageType="clipped"
            />
          </div>
          <div ref={card}>
            <CardAbout
              title={"Emma"}
              q1={"Bass"}
              q2={"Everything from emo to prog to modern metal, so quite a weird mix! If I had to choose bands, I'd say Porcupine Tree, My Chemical Romance, Rush, Biffy Clyro, Fearless Vampire Killers, The Cure, early Hands Like Houses (and a lot of other 2010s metalcore/post-hardcore), Muse, Depeche Mode, Placebo."}
              q3={"Likes: Coloured vinyl, cool clothes, going to gigs, maths and physics, gothic romance movies, Mighty Boosh, Tim Minchin, Vic and Bob."}
              q4={"Favourite song we cover: definitely Fear of the Dark!"}
              q5={"\"You're still not cool, and you're never gonna be cool, so stop trying.\" - Gerard Way"}
              q6={"Such a hard decision! My first gig was Fearless Vampire Killers when I was 10 and it changed my life, that's when I knew that I wanted to start making music myself. Being on the barrier for Biffy Clyro and Architects was insane though too, as was the gig where Ville Valo from HIM blew me a kiss- I had to pinch myself!"}
              image={emma}
              type="reverse"
              imageType="clipped"
            />
          </div>
          <div ref={card}>
            <CardAbout
              title={"Nige"}
              q1={"Guitar"}
              q2={"Zep, Sabbath, Thin Lizzy, Rory Gallagher, Free, Van Halen and Rush.  Just about any band with lots of hair between 1968 and 1978"}
              q3={"Les Pauls, breathing, California, reading fiction, chocolate, nice wine, BCFC (KRO!!), spaniels (and their walkies), all sorts of movies, coffee and cake.  Did I mention Les Pauls?"}
              q4={""}
              q5={"\"If you didn’t do anything that wasn’t good for you it would be a very dull life. What are you gonna do? Everything that is pleasant in life is dangerous.\"  Lemmy"}
              q6={"Thin Lizzy, Black Rose tour, Birmingham Odeon, 2 April 1979. I’ve never seen a crowd sing every word and jump around quite like that. In a proper venue too, not one of those great big echoey warehouses where you can’t see the musicians"}
              image={nige}
              type="half"
              imageType="clipped"
            />
          </div>
          <div ref={card}>
            <CardAbout
              title={"Adam"}
              q1={"Drums"}
              q2={"To name a few, Dream Theater, Rush,  Brand New Heavies, The Carpenters, Earth Wind and Fire, this diverse list could go on."}
              q3={"Curry, animals (not curried animals), going to gigs, hot holidays, sci fi, gaming and spending time with family. Oh and playing drums."}
              q4={""}
              q5={"\"Before you judge a man, walk a mile in his shoes. After that who cares?..... he's a mile away and you've got his shoes!!!\" - Billy Connolly"}
              q6={"Probably my first as the memory has lasted 30 years! Milton Keynes Bowl 1993 Metallica, Megadeath, The Almighty and Diamond Head!! Although every Rammstein gig has been incredible too"}
              image={adam}
              type="reverse"
              imageType="clipped"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

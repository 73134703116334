import React from "react";
import Slider from "react-slick";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <h1 className='carousel__title'>Our Reviews</h1>
      <Slider {...settings}>
        <div className='carousel__card'>
          <h4 className='carousel__text'>
            "This was such an entertaining great band and the poor singer
            got up from his sick bed to deliver a wonderful set.
            Much appreciated Kalashnikov Skunk, thanks for a fabulous
            night of entertainment!"
          </h4>
          <h5 className='carousel__name'>Tracy Winner-Scott</h5>
        </div>
        <div className='carousel__card'>
          <h4 className='carousel__text'>
            "Enjoyed that. Good band. Hardly any songs I didn't know, which is a bonus. Yep very talented all of them.. Thanks very much."
          </h4>
          <h5 className='carousel__name'>Dave Boston</h5>
        </div>
        <div className='carousel__card'>
          <h4 className='carousel__text'>
            "Highly recommend this band, great stage presence, energy and enthusiasm!"
          </h4>
          <h5 className='carousel__name'>Sound55</h5>
        </div>
      </Slider>
    </>
  );
}
import { NavLink, Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import BookUs from "./BookUs";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import logo from "../img/logo/logo-new.png";
import facebookIcon from "../img/icons/facebook.svg";
import instagramIcon from "../img/icons/instagram.svg";
import emailIcon from "../img/icons/email.svg";

gsap.registerPlugin(ScrollTrigger);

const Nav = () => {
  const nav = useRef();
  const [showNav, setShowNav] = useState(false);

  const toggleNavMenu = () => {
    setShowNav(!showNav);
  };

  const toggleNav = () => {
    setShowNav(false);
  };

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.fromTo(".nav__logo", {
        width: "450px",
      }, {
        scrollTrigger: {
          trigger: ".nav__logo",
          start: "top top",
          bottom: "bottom top",
          scrub: 1,
        },
        width: "150px",
        top: "15px",
        position: "fixed",
        duration: 1,
      });
      gsap.fromTo(".nav__item-wrapper", {
        x: 0,
      }, {
        scrollTrigger: {
          trigger: ".nav__logo",
          start: "top top",
          bottom: "bottom top",
          scrub: 1,
        },
        x: 200,
      });
    }, nav);
  }, []);

  return (
    <nav className="nav" ref={nav}>
      <div className="nav__background"></div>
      <div className="nav__mobile">
        <Link to="/" onClick={toggleNav}>
          <img src={logo} alt="kalashnikov skunk band logo" className="nav__logo--mobile" />
        </Link>
        <button className={`nav__toggle ${showNav ? "nav__toggle--open" : ""}`} onClick={toggleNavMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <ul className={`nav__wrapper ${showNav ? "nav__wrapper--open" : ""}`} >
        <div className="nav__social">
          <a className="nav__icon" href="https://www.facebook.com/kalashnikov.skunk/" rel="noreferrer" target="_blank">
            <img src={facebookIcon} alt="facebook" width="30" height="30" />
          </a>
          <a className="nav__icon" href="https://www.instagram.com/kalashnikov_skunk/" rel="noreferrer" target="_blank">
            <img src={instagramIcon} alt="instagram" width="30" height="30" />
          </a>
          <BookUs />
        </div>
        <div className="nav__social--mobile">
          <a className="nav__icon" href="https://www.facebook.com/kalashnikov.skunk/" rel="noreferrer" target="_blank">
            <img src={facebookIcon} alt="facebook" width="30" height="30" />
          </a>
          <a className="nav__icon" href="https://www.instagram.com/kalashnikov_skunk/" rel="noreferrer" target="_blank">
            <img src={instagramIcon} alt="instagram" width="30" height="30" />
          </a>
          <a className="nav__icon" href="mailto:kalashnikovskunk@yahoo.com" rel="noreferrer" target="_blank">
            <img src={emailIcon} alt="email" width="30" height="30" />
          </a>
        </div>
        <img src={logo} alt="kalashnikov skunk band logo" className="nav__logo" />
        <div className="nav__item-wrapper">
          <li className="nav__item">
            <NavLink to="/" className="nav__link" href="#home" onClick={toggleNav}>
              Home
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink to="/Gigs" className="nav__link" href="#gigs" onClick={toggleNav}>
              Gigs
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink to="/About" className="nav__link" href="#news" onClick={toggleNav}>
              BAND
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink to="/Gallery" className="nav__link" href="#gallery" onClick={toggleNav}>
              Media
            </NavLink>
          </li>
        </div>
      </ul>
    </nav>
  );
};

export default Nav;

import React, { useRef } from "react";

const Video = (props) => {
    const videoRef= useRef();
    const setPlayBack = () => {
        videoRef.current.playbackRate = 1;
    };
    return (
    <video 
        className={props.type} 
        ref={videoRef}
        autoPlay 
        loop 
        muted 
        playsInline
        src={props.src} 
        type="video/mp4"
        onCanPlay={() => setPlayBack()}
        >
    </video>
)};

export default Video;

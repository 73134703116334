import React, { useState } from "react";
import GalleryModal from "../components/GalleryModal";
import imgUrls from "../img/gallery/galleryImagesList";

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(null);

  const closeModal = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setCurrentIndex(null);
  };

  const findPrev = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setCurrentIndex((prevIndex) => (prevIndex - 1));
  };

  const findNext = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setCurrentIndex((prevIndex) => (prevIndex + 1));
  };

  const renderImageContent = (image, index) => (
    <div className="gallery-grid__item" key={index} onClick={() => setCurrentIndex(index)}>
      <img src={image.src} alt={image.alt} style={{ objectPosition: image.position }} />
      {image.location && <p className="gallery__location">{image.date}</p>}
      {image.photographer && <a className="gallery__photographer" target="_blank" href={image.photographerLink} rel="noreferrer">{image.photographer}</a>}
    </div>
  );

  return (
    <>
      <div className="app__background gallery">
        <div className="app__layout">
          <h1 className="card__title--large">Latest gig photos</h1>
          <div className="gallery-grid">
            {imgUrls.map(renderImageContent)}
          </div>
          <GalleryModal
            closeModal={closeModal}
            findPrev={findPrev}
            findNext={findNext}
            hasPrev={currentIndex > 0}
            hasNext={currentIndex + 1 < imgUrls.length}
            image={imgUrls[currentIndex]}
          />
        </div>
      </div>
    </>
  );
};

export default Gallery;

import React from "react";

const BookUs = () => {
  return (
    <div className="book">
      <a target="_blank" href="mailto:kalashnikovskunk@yahoo.com" className="book__btn" rel="noreferrer">Book Us</a>
    </div>
  );
};

export default BookUs;

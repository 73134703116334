import React from "react";
import Hero from "../components/Hero";
import Gallery from "../components/Gallery";
import { Helmet } from 'react-helmet';
import heroImage from "../img/heros/IMG_5.jpg";


const GalleryPage = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://skunksters.com/Gallery" />
      </Helmet>
      <Hero image imageSrc={heroImage} />
      <Gallery />
    </>
  );
};

export default GalleryPage;

import React from "react";

const CardEvent = (props) => {
  return (
    <div className={`card card--event fade-in ${props.facebook ? 'card--facebook': ''}`}>
      {
        props.completed &&  <div className="card--event__overlay">
          <h1>Skunked</h1>
        </div>
      }
      <p className="card__date">{props.date}</p>
      <h1 className="card__title card__title--dark">{props.title}</h1>
      <a className="card__location" target="_blank" href={props.locationLink}>{props.location}</a>
    </div>
  );
};

export default CardEvent;

import heroImage from "../img/heros/4042.jpg";
import Video from "./Video"
import defaultVideoSrc from "../video/KSHeroVid2.mp4";

const Hero = (props) => {
  const {
    image = false,
    video = false,
    isBackground = false,
    imageSrc = heroImage,
    videoSrc = defaultVideoSrc,
  } = props

  return (
    <div className="hero">
      <div className={`hero__wrapper fade-in`}>
      </div>
      <div className={`hero__background fade-in ${video && 'hero__background--video'}`}>
        {image &&
          <img src={imageSrc} className="hero__image" alt="kalashnikov skunk band hero"></img>
        }
        {video &&
          <Video src={videoSrc} type="hero__video" />
        }
      </div>
    </div>
  );
};

export default Hero;

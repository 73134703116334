import React from "react";

const CardAbout= (props) => {
  return (
    <div className={`card card--half card--${props.type}`}>
        <div className='col card__col'>
          <h1 className="card__title card__title--large">{props.title}</h1>
          <div className="col pt-15">
            { props.q1 && <><h4 className="card__question">Kalashnikov Skunk's:</h4>
            <p className="card__text card__answer">{props.q1}</p></>}
          </div>
          <div className="col pt-15">
            { props.q2 && <><h4 className="card__question">Musical influences:</h4>
            <p className="card__text card__answer">{props.q2}</p></>}
          </div>
          <div className="col pt-15">
            { props.q3 && <><h4 className="card__question">Likes:</h4>
            <p className="card__text card__answer">{props.q3}</p></>}
          </div>
          <div className="col pt-15">
          { props.q4 && <><h4 className="card__question">Favourite song we cover: </h4>
            <p className="card__text card__answer">{props.q4}</p></>}
          </div>
          <div className="col pt-15">
          { props.q5 && <><h4 className="card__question">Favourite quote: </h4>
            <p className="card__text card__answer">{props.q5}</p></>}
          </div>
          <div className="col pt-15">
            { props.q6 && <><h4 className="card__question">Best ever gig: </h4>
            <p className="card__text card__answer">{props.q6}</p></>}
          </div>
        </div>
        <div className="col card__col">
          { props.image &&
            <img className={`card__image card__image--${props.imageType}`} src={props.image} alt={props.title} />
          }
        </div>
    </div>
  );
};

export default CardAbout;
